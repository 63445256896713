import { LanguageLink } from '@goed-platform/shared/ui';
import { useTranslation } from 'next-i18next';

export type CategoriesProps = {
    displayParentCategory?: boolean;
    currentCategory: CategoryItemProps;
    subCategories?: CategoryItemProps[];
    parentSubCategories?: CategoryItemProps[];
};

export type CategoryItemProps = {
    label: string;
    href?: string;
};

export const Categories: React.FC<CategoriesProps> = ({
    displayParentCategory = true,
    currentCategory,
    subCategories = [],
    parentSubCategories = [],
    ...restProps
}: CategoriesProps): JSX.Element => {
    const { t } = useTranslation();

    // Check if parent categories need to be displayed or subcategories
    const desktopSubCategories = subCategories && subCategories.length > 0 ? subCategories : parentSubCategories;

    return (
        <div {...restProps}>
            {/* Desktop (LG) */}
            <div className="d-none d-lg-flex">
                <div className="d-flex flex-column">
                    {/* Current category */}
                    <div className="pb-4 font-weight-bold font-size-18">{t('categories.title')}</div>

                    {/* Subcategories */}
                    {desktopSubCategories && (
                        <div className="d-flex">
                            <div className="d-flex flex-column pl-4 border-left border-left-2 border-neutral-a5">
                                {desktopSubCategories?.map((category, key) => (
                                    <LanguageLink key={key} href={category.href ?? ''}>
                                        <a
                                            className={`unstyled font-size-18 ${key ? 'pt-3' : 'pt-1'} pb-1 ${
                                                currentCategory.label === category.label
                                                    ? 'font-weight-bold category-active'
                                                    : ''
                                            }`}
                                        >
                                            {category.label.toLowerCase()}
                                        </a>
                                    </LanguageLink>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
            </div>

            {/* Mobile */}
            <div className="d-lg-none">
                {/* Subcategories */}
                <div
                    className={`d-flex align-items-center flex-wrap ${
                        subCategories && subCategories.length > 0 ? 'mt-4' : ''
                    }`}
                >
                    {subCategories?.map((category, key) => (
                        <LanguageLink key={key} href={category.href ?? ''}>
                            <a className="unstyled px-2 py-1 mr-2 mb-2 text-neutral bg-neutral-white font-size-14 rounded border border-neutral-a10">
                                {category?.label.toLowerCase()}
                            </a>
                        </LanguageLink>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Categories;
