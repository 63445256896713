import { BreadcrumbItem } from '@goed-platform/breadcrumb/type';
import { Breadcrumbs } from '@goed-platform/breadcrumb/ui';
import { CategoryBlock } from '@goed-platform/categories/ui';
import { ImageLayoutEnum, ImageObjectfitEnum } from '@goed-platform/product/enums';
import { Config, nextI18nextConfig } from '@goed-platform/shared/constants';
import { CustomAppProps, RoutesEnum } from '@goed-platform/shared/types';
import { BaseLayout, Button, ButtonSizeEnum, OptimizedImage, SEOTags } from '@goed-platform/shared/ui';
import { SVGExternalLink, UrlUtils } from '@goed-platform/shared/utils';
import { SymfonyDataAccess } from '@goed-platform/symfony/data-access';
import { SymfonyCategory } from '@goed-platform/symfony/types';
import { GetStaticProps } from 'next';
import { UserConfig, useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import Link from 'next/link';
import { FC } from 'react';

export const getStaticProps: GetStaticProps = async ({ locale }) => {
    const categories = await SymfonyDataAccess.getInstance().getShopGlobalCategories();

    return {
        props: {
            base: {
                symfonyCategories: categories ?? [],
            },
            ...(await serverSideTranslations(locale, ['common'], nextI18nextConfig as UserConfig)),
        },
        revalidate: Config.global.revalidation,
    };
};

type PageProps = CustomAppProps & {
    base: {
        symfonyCategories: SymfonyCategory[];
    };
};

const Page: FC<PageProps> = ({ base, navigation }: PageProps): JSX.Element => {
    const { t } = useTranslation();

    const breadcrumbs: BreadcrumbItem[] = [
        {
            label: t('general.footer.navigation.suggestion.title'),
        },
    ];

    return (
        <BaseLayout navigation={navigation}>
            <SEOTags
                pageTitle={t('global.subheader.primaryLinks.label.1')}
                metaDescription={t('general.pages.total-catalog.meta.description')}
            />
            <Breadcrumbs items={breadcrumbs} />

            <div className="container">
                <section className="mb-12 mt-md-12 bg-neutral-white rounded-lg overflow-hidden">
                    <div className="row">
                        <div className="col-12 col-md-6 order-2 order-md-1">
                            <div className="p-4 p-md-6 px-lg-8 py-lg-12 d-flex flex-column justify-content-center">
                                <div className="h3 text-primary mb-4">{t('general.pages.home.hero.title')}</div>
                                <p>{t('general.pages.home.hero.description')}</p>
                                <div className="d-flex flex-column flex-md-row align-items-md-center">
                                    <Link href={RoutesEnum.rentalStoreLanding}>
                                        <a className="unstyled mb-2 mb-md-0 mr-md-4">
                                            <Button
                                                className="w-fit"
                                                size={ButtonSizeEnum.medium}
                                                showArrow={false}
                                                label={t('general.pages.home.hero.cta')}
                                            />
                                        </a>
                                    </Link>
                                    {/* <Link>
                                        <a>{t('general.pages.home.hero.moreInfoULD')}</a>
                                    </Link> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 order-1 order-md-2 hs-40 h-md-auto p-0 bg-img-size-cover bg-img-no-repeat bg-img-position-center">
                            <OptimizedImage
                                image={{
                                    sortOrder: 0,
                                    url: '/assets/images/jpg/goed-hulpmiddel.jpg',
                                }}
                                layout={ImageLayoutEnum.fill}
                                objectFit={ImageObjectfitEnum.cover}
                                alt={t('hulpmobiel')}
                                priority={false}
                            />
                        </div>
                    </div>
                </section>
                {/* Root category overview */}
                <div className="h3 mb-3 mb-md-12 text-neutral font-size-24 font-size-md-28">
                    {t('general.pages.home.title')}
                </div>
                <section className="d-flex row mb-6">
                    {base?.symfonyCategories?.map((category, key) => (
                        <div className="col-12 col-md-6 col-lg-4 mb-md-8 mb-lg-16" key={key}>
                            <CategoryBlock
                                className="d-none d-md-block"
                                href={category.link}
                                imageUrl={category.image_path}
                                label={category.name}
                                imagePriority
                                externalLink={UrlUtils.isExternalUrl(category.link)}
                                showBorder={false}
                            />
                            <Link href={category.link}>
                                <a
                                    target={UrlUtils.isExternalUrl(category.link) ? '_blank' : '_self'}
                                    className="d-flex d-md-none justify-content-between align-items-center py-3"
                                >
                                    {category.name}
                                    {UrlUtils.isExternalUrl(category.link) ? (
                                        <SVGExternalLink className="sprite sprite-md ml-1" />
                                    ) : (
                                        <></>
                                    )}
                                </a>
                            </Link>
                        </div>
                    ))}
                </section>
            </div>
        </BaseLayout>
    );
};

export default Page;
