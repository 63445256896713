import { ImageLayoutEnum, ImageRatio } from '@goed-platform/product/enums';
import { fallbackImageUrl } from '@goed-platform/shared/constants';
import { OptimizedImage } from '@goed-platform/shared/ui';
import { SVGExternalLink } from '@goed-platform/shared/utils';
import Link from 'next/link';
import { PropsWithChildren } from 'react';

type CategoryBlockProps = PropsWithChildren<{
    label?: string;
    href: string;
    imageUrl?: string;
    className?: string;
    imageRatio?: ImageRatio;
    externalLink?: boolean;
    imagePriority?: boolean;
    showBorder?: boolean;
}>;

export const CategoryBlock = ({
    label = '',
    href,
    imageUrl,
    className = '',
    imageRatio = ImageRatio.is2by1,
    externalLink = false,
    imagePriority = false,
    showBorder = true,
    ...restProps
}: CategoryBlockProps): JSX.Element => {
    return (
        <div
            className={`w-100 position-relative ${
                showBorder ? 'pb-4 border-purple-x-light border-bottom-dotted' : ''
            } ${className}`}
            {...restProps}
        >
            {!!imageUrl && (
                <OptimizedImage
                    image={{
                        sortOrder: 0,
                        url: imageUrl ?? fallbackImageUrl,
                    }}
                    alt={label}
                    className={`embed-responsive embed-responsive-${imageRatio} mb-2 mb-lg-4 rounded-md`}
                    layout={ImageLayoutEnum.fill}
                    priority={imagePriority}
                />
            )}
            <Link href={href}>
                <a
                    target={externalLink ? '_blank' : '_self'}
                    className="stretched-link display-h5 font-size-16 font-size-lg-20 mb-4 border-0"
                >
                    {label}
                    {externalLink ? <SVGExternalLink className="sprite sprite-md ml-1" /> : ''}
                </a>
            </Link>
        </div>
    );
};
